.container {
    max-width: 50rem;
    padding: 0 1rem;
    margin: 3rem auto 6rem;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.headerImage {
    width: 6rem;
    height: 6rem;
}

.headerHomeImage {
    width: 8rem;
    height: 8rem;
}

.backToHome {
    margin: 3rem 0 0;
}